<template>
  <div class="error">
    <section class="section-error">
      <div class="container">
        <Row type="flex" justify="center">
          <Col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
            <div class="content">
              <div class="headline">
                <h2 class="title">404</h2>
                <div class="description">Stranica koju tražite nije pronađena ili je premještena</div>
              </div>
              <div class="action">
                <router-link :to="{ name: $store.state.accessToken ? 'ApplicationJarsRootIndex' : 'HomeIndex' }">
                  Povratak na početak
                </router-link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  </div>
</template>

<script>
// Mixins
import {Common} from '@/mixins/Common';

// Element UI
import {Row, Col, Button} from 'element-ui';

// Logic
export default {
  name: 'PageNotFoundIndex',
  mixins: [Common],
  components: {
    Row,
    Col,
    Button
  },
  mounted() {
    this.initialize();
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/core/_variables.scss';
@import '@/assets/scss/mixins/_functions.scss';
@import '@/assets/scss/mixins/_breakpoints.scss';

.section-error {
  display: flex;
  align-items: center;
  min-height: 100vh;

  .content {
    .headline {
      text-align: center;

      .title {
        font-size: 28px;
        font-weight: 400;
        margin: 0;

        // Mobile
        @include media-breakpoint-down(sm) {
          font-size: 20px;
        }
      }

      .description {
        font-size: 16px;
        margin: 16px 0 0;
      }
    }

    .action {
      margin: 32px 0 0;
      text-align: center;
    }
  }
}
</style>
